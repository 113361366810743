import {defineStore} from 'pinia';
// import {getPageName} from "@/operating";
// import { viewEtpUserToDo } from "@/main"
import axios from "axios";

let originalSetInterval = window.setInterval;
let intervalTimerList = [];


export const viewData = defineStore('viewData', {
    state: () => {
        return {
            isLogin: false,
            isMobile: true,
            showSubMenu: false,
            currentUser: {},
            currentUserId: '',
            currentApplicationUser:{},
            currentEtpTrader:{},
            pageAreaName: '',
            pageTitle: '',
            menuTitle: '',
            mainMenuName: '',
            showDrawer: false,
            drawerName: '',
            drawerTitle: '',
            drawerWidth: 600,
            drawerParameter: {},
            currentPage: null,
            currentDrawer: null,

            ssoUrl: '',
            ssoReady: false,
        }
    },
    getters: {
    },
    actions: {
        hasRole(role) {
            return this.currentUser.roles.includes(role)
        },
        handleWindowResize() {
            const mobileWidthThreshold = 768;
                if (this.windowWidth <= mobileWidthThreshold) {
                this.showSubMenu = false;
                this.isMobile = true
            }else{
                this.showSubMenu = true;
                this.isMobile = false
            }
            try{
                this.drawerWidth = window.innerWidth * 0.8;
                if (window.innerWidth >= 969) this.drawerWidth = window.innerWidth * 0.6;
                if (window.innerWidth >= 1500) this.drawerWidth = window.innerWidth * 0.4;
            }catch(err){
                console.log(err);
            }
        },
        openDrawer(drawerName, drawerTitle, drawerParameter) {
            drawerTitle = drawerTitle || ''
            drawerParameter = drawerParameter || {}
            this.showDrawer = true
            this.drawerTitle = drawerTitle
            this.drawerParameter = drawerParameter
            setTimeout(() => {
                this.drawerName = drawerName
            }, 330)
        },
        setPageInterval(callback, ms, keep = false) {
            keep = keep || false
            let i = originalSetInterval(callback, ms);
            if (!keep) intervalTimerList.push(i);
        },
        clearPageInterval() {
            intervalTimerList.forEach(i => {
                clearInterval(i);
            });
            intervalTimerList = [];
        },
        async canUserAccess() {
            if (this.isLogin === false) return false
            if (this.currentUser.roles.includes('admin')) return true
            return true
        },
        async beforePageChange() {
            this.clearPageInterval()
        },
        afterPageChange() {
            let path = location.pathname
            let pageName = path.split('/').join('_').substring(1)
            if (pageName.length === 1) pageName = 'components_' + pageName
            this.pageAreaName = pageName
            setTimeout(() => {
                this.mainMenuName = pageName.split("_")[0]
            }, 30)
        },
        ssoCheck(){
            let v = this
            return new Promise((resolve, reject) => {
                let p = location.hostname
                if (p === 'localhost' || p === '127.0.0.1'){
                    p = `${location.protocol}//${location.hostname}:${location.port}`
                    if (p.endsWith(':')) p = p.substring(0, p.length - 1)
                }else{
                    p = p.replace('.greenshepherd.com.tw', '')
                }

                axios.get(`/api/sso/check?service=${encodeURI(p)}`).then(res => {
                    console.log(res.data)
                    v.isLogin = res.data.isLogin
                    v.currentUser = res.data.data
                    if (!v.currentUser) v.currentUser = {'roles': []}
                    if (v.currentUser.user_roles) v.currentUser.roles = v.currentUser.user_roles.split(',')
                    v.ssoUrl = res.data.url
                    v.ssoReady = v.ssoUrl !== ''
                    this.getApplicationUser()
                    resolve(res);
                }).catch(err => reject(err));
            });
        },
        getApplicationUser(){
          let formData={'account':this.currentUser.user}
          axios.post('/api/user/get_application_user',this.getFormData(formData)).then(res=>{
            console.log(res.data)
              this.currentApplicationUser=res.data.data
              this.getEtpTrader()
          })
        },
        getEtpTrader(){
          let formData={'user_id':this.currentApplicationUser.id}
          axios.post('/api/user/get_etp_trader',this.getFormData(formData)).then(res=>{
            console.log(res.data)
              this.currentEtpTrader=res.data.data
          })
        },
        getFormData(object) {
            let formData = new FormData();
            Object.keys(object).forEach(key => formData.append(key, object[key]));
            return formData;
        },
    },
})

export default viewData