export default {
    install: (app) => {
        const api = new api_class(app)
        const td_data = new td_class(app)
        app.config.globalProperties.api = api
        app.config.globalProperties.TDEngine = td_data
    }
}

import axios from 'axios'

class api_class{
    app = null
    constructor(app){
        this.app = app
    }
    get(url){
        let old_path = [
            '/api/chat/q.json',
            // '/api/device/list.json',
            '/api/field/ids',
            // '/api/field/list',

            // '/api/device_error/list.json',
            // '/api/biogas_maintain/list.json',
            // '/api/cron_item/list.json',
            // '/api/dispatch/list.json',
            // '/api/warranty/list',
            // '/api/issue/list.json',

            '/api/nas_proxy/last_photos',
            '/api/generator/realtime',
            '/api/generator/list',
            '/api/device/anydesk.json',
            '/api/dispatch/list_for_calendar',
            '/api/cron_item/list'
        ]
        old_path.forEach(p=>{
            if (url.startsWith(p)) url = 'https://www.greenshepherd.com.tw' + url
        })
        return axios.get(url)
    }
    patch(url, data){
        let notice = this.app.config.globalProperties.$Notice
        notice.config({
            top: 65,
            duration: 2.5
        });
        return axios.patch(url, data).then(res=>{
            if (res.data.update === 1) {
                notice.success({
                    title: '已更新',
                });
            }else{
                if (res.data.err) {
                    notice.error({
                        title: res.data.err,
                    });
                }else{
                    notice.info({
                        title: '沒有變更',
                    });
                }
            }
            return res
        }).catch(err=>{
            if (err.response.data.message) {
                notice.info({
                    title: err.response.data.message,
                });
            }else{
                notice.error({
                    title: err.message,
                });
            }
            return err
        })
    }
    post(url, data){
        let notice = this.app.config.globalProperties.$Notice
        notice.config({
            top: 70,
            duration: 1.8
        });
        return axios.post(url, data).then(res=>{
            if (res.data.message) {
                let msg = res.data.message.replace('login success', '登入成功')
                notice.info({
                    title: msg,
                });
            }else{
                if (res.data.insert > 0){
                    notice.success({
                        title: '已新增',
                    });
                }else{
                    if (res.data.err) {
                        notice.error({
                            title: res.data.err,
                        });
                    }
                }
            }
            return res
        }).catch(err=>{
            console.log(err)
            if (err.response.data.message) {
                notice.info({
                    title: err.response.data.message,
                });
            }else{
                notice.error({
                    title: err.message,
                });
            }
            return err
        })
    }
    delete(url){
        let notice = this.app.config.globalProperties.$Notice
        notice.config({
            top: 70,
            duration: 1.8
        });
        return axios.delete(url).then(res=>{
            if (res.data.delete > 0){
                notice.success({
                    title: '已刪除',
                });
            }else{
                if (res.data.err) {
                    notice.error({
                        title: res.data.err,
                    });
                }
            }
        });
    }
}
class td_class{
    app = null
    constructor(app){
        this.app = app
    }
    get(code){
        let url = 'https://us-central1-greenshepherdcomtw.cloudfunctions.net/td-engine-endpoint?code=' + code
        return axios.get(url)
    }
    async getValueInDate(field_name, datetime){
        let d = datetime
        d.setHours(0, 0, 0, 0)
        let UTCTimeStart = `${d.getUTCFullYear()}-${d.getUTCMonth()+1}-${d.getUTCDate()} ${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}`
        d.setHours(23, 59, 59, 999)
        let UTCTimeEnd = `${d.getUTCFullYear()}-${d.getUTCMonth()+1}-${d.getUTCDate()} ${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}`
        let code = `select max(value) as max, min(value) as min from db.${field_name} where ts < '${UTCTimeEnd}' and ts >= '${UTCTimeStart}' and value > 0.0`
        return new Promise((resolve, reject)=> {
            this.get(code).then(d=>{
                if (d['data']['data'] && d['data']['data'].length > 0){
                    let max = d['data']['data'][0][0]
                    let min = d['data']['data'][0][1]
                    resolve(max - min)
                }else{
                    reject(0.0)
                }
            }, function(){
                reject(0.0)
            })
        })
    }
}
