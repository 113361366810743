export {pageData};

let pageData = {
    'login':[
        {
            title: '登入',
            name: 'user',
            items: [
                {dir: 'user', title: '登入頁面', page: 'Login'},
            ]
        }
    ],
    'nav': [
        {
            title: '服務',
            name: 'service',
            items: [
                {dir: 'service', title: '個人資訊', page: 'Information'},
                {dir: 'service', title: '碳排盤查', page: 'esg'},
                {dir: 'service', title: '電廠數據', page: 'vpp'},
                {dir: 'service', title: '電力交易', page: 'trade'},
                {dir: 'service', title: '案件流程', page: 'afu'},
                {dir: 'service', title: '智能分析', page: 'ai'},
                {dir: 'service', title: '維運派工', page: 'om'},
                {dir: 'service', title: '案場權限管理', page: 'FieldPermissions'},
            ]
        },
        {
            title: '偏好',
            name: 'preferences',
            items: [
                {dir: 'preferences', title: '案場權限管理', page: 'Preference'},
            ]
        },
        {
            title: '通知',
            name: 'notification',
            items: [
                {dir: 'notification', title: '通知設定', page: 'Notification'},
            ]
        },
    ]
}
